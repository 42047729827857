<template>
  <div class="wap-open-shop">
    <headerBar :title="title" @back="back"></headerBar>

    <div class="top">
      <div class="flex-center-between header">
        <div class="logo flex-center-start" @click="toPath('index')">
          <img :src="logo || logoUrl" alt="" />
          <span class="app-name">{{ appName }}</span>
        </div>
        <div class="flex-center lang-box">
          <el-dropdown trigger="click">
            <div class="lang-info flex-center">
              <img :src="langIcon" alt="" />
              <i
                class="el-icon-caret-bottom"
                style="color: #fff; font-size: 14px"
              ></i>
            </div>
            <el-dropdown-menu slot="dropdown" class="lang-dropdown">
              <el-dropdown-item v-for="(item, i) in langList" :key="i">
                <div
                  class="flex-center-start lang-menu"
                  @click="changeLang(item)"
                >
                  <img :src="item.icon" alt="" />
                  <span>{{ item.name }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="title">{{ $t('appname-shang-jia-ru-zhu', [appName]) }}</div>
      <div class="sub-title">
        {{
          $t(
            'wo-men-de-he-zuo-huo-ban-ji-hua-wei-nin-ti-gong-quan-fang-wei-de-ying-xiao-zhi-chi-he-fu-wu-wo-men-de-ke-hu-fu-wu-tuan-dui-jiang-ti-gong-zhuan-ye-de-zhi-chi-he-jian-yi-bang-zhu-nin-you-hua-nin-de-chan-pin-zhan-shi-he-xiao-shou-ce-lve'
          )
        }}
      </div>
      <div class="sub-title">
        {{
          $t(
            'xian-zai-jiu-jia-ru-wo-men-ba-rang-wo-men-yi-qi-chuang-zao-geng-da-de-shang-ye-ji-hui-gong-tong-cheng-chang'
          )
        }}
      </div>
    </div>

    <div class="main-form">
      <router-view></router-view>
    </div>

    <footerBar></footerBar>
  </div>
</template>
<script>
import { Button, Popup, Checkbox, Field } from 'vant'
import headerBar from '@/components/header'
import CountryList from '@/components/countryList'
import { getToken } from '@/utils/auth'
import { openShop } from '@/api/shop'
import footerBar from '@/components/footer/wapFooter'
export default {
  components: {
    CountryList,
    Button,
    headerBar,
    Checkbox,
    Popup,
    Field,
    footerBar
  },
  data() {
    return {
      logoUrl: require('@/assets/imgs/logo.png'),
      checked: false,
      avatar: '',
      form: {
        phone: '',
        phonePre: '+86'
      },
      headers: {},
      title: ''
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    },
    appName() {
      return this.$store.state.appName
    },
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  watch: {
    $route() {
      this.getTitle()
    }
  },
  mounted() {
    this.getTitle()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getTitle() {
      let name = this.$route.name
      switch (name) {
        case 'wapPrivacy':
          this.title = this.$t('yin-si-zheng-ce')
          break
        case 'wapRefundRole':
          this.title = this.$t('tui-huo-zheng-ce')
          break
        case 'wapDelivery':
          this.title = this.$t('song-huo-ji-qu-huo')
          break
        case 'wapShippingPolicy':
          this.title = this.$t('mai-jia-zheng-ce')
          break
        case 'wapAbout':
          this.title = this.$t('guan-yu-wo-men')
          break
      }
      console.log(11, this.title)
    },
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      this.$router.push(path)
    },

    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.$store.state.lang = data.key
      this.$store.state.langIcon = data.icon
      this.$store.state.langName = data.name
    }
  }
}
</script>